/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */
import { useMutation } from "@tanstack/react-query";
import type {
  MutationFunction,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import type { HTTPValidationError, PantheonUserSignup } from ".././schema";
import { axiosInstance } from "../../lib/axios";
import type { ErrorType } from "../../lib/axios";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Create Pantheon Signup
 */
export const createPantheonSignup = (
  pantheonUserSignup: PantheonUserSignup,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<unknown>(
    {
      url: `/api/v1/signup`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: pantheonUserSignup,
    },
    options
  );
};

export const getCreatePantheonSignupMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPantheonSignup>>,
    TError,
    { data: PantheonUserSignup },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createPantheonSignup>>,
  TError,
  { data: PantheonUserSignup },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createPantheonSignup>>,
    { data: PantheonUserSignup }
  > = (props) => {
    const { data } = props ?? {};

    return createPantheonSignup(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreatePantheonSignupMutationResult = NonNullable<
  Awaited<ReturnType<typeof createPantheonSignup>>
>;
export type CreatePantheonSignupMutationBody = PantheonUserSignup;
export type CreatePantheonSignupMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Create Pantheon Signup
 */
export const useCreatePantheonSignup = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPantheonSignup>>,
    TError,
    { data: PantheonUserSignup },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createPantheonSignup>>,
  TError,
  { data: PantheonUserSignup },
  TContext
> => {
  const mutationOptions = getCreatePantheonSignupMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Validate Pantheon Signup
 */
export const validatePantheonSignup = (
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<unknown>(
    { url: `/api/v1/validate`, method: "POST" },
    options
  );
};

export const getValidatePantheonSignupMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof validatePantheonSignup>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof validatePantheonSignup>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof validatePantheonSignup>>,
    void
  > = () => {
    return validatePantheonSignup(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ValidatePantheonSignupMutationResult = NonNullable<
  Awaited<ReturnType<typeof validatePantheonSignup>>
>;

export type ValidatePantheonSignupMutationError = ErrorType<unknown>;

/**
 * @summary Validate Pantheon Signup
 */
export const useValidatePantheonSignup = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof validatePantheonSignup>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof validatePantheonSignup>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getValidatePantheonSignupMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Award Points For Video
 */
export const awardPointsForVideo = (
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<unknown>(
    { url: `/api/v1/actions/award-points-for-video`, method: "POST" },
    options
  );
};

export const getAwardPointsForVideoMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof awardPointsForVideo>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof awardPointsForVideo>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof awardPointsForVideo>>,
    void
  > = () => {
    return awardPointsForVideo(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AwardPointsForVideoMutationResult = NonNullable<
  Awaited<ReturnType<typeof awardPointsForVideo>>
>;

export type AwardPointsForVideoMutationError = ErrorType<unknown>;

/**
 * @summary Award Points For Video
 */
export const useAwardPointsForVideo = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof awardPointsForVideo>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof awardPointsForVideo>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getAwardPointsForVideoMutationOptions(options);

  return useMutation(mutationOptions);
};
