import { useCallback, useEffect, useMemo, useState } from "react";

import { useAnalytics } from "@/lib/analytics/useAnalytics";

import { subscribeUser, unsubscribeUser } from "@/upsmith-api";

import { NotificationSubscriptionService } from "./types";
import { urlBase64ToUint8Array } from "./urlBase64ToUint8Array";
import { isUsingPwa } from "../isUsingPwa";

export const useNotificationSubscriptionService = (
  tenantUuid: string
): NotificationSubscriptionService => {
  const analytics = useAnalytics();
  // ========================= STATE =========================
  const [isSupportedByBrowser, setIsSupportedByBrowser] = useState(false);

  const [subscription, setSubscription] = useState<
    PushSubscription | null | undefined
  >(undefined);

  const [permissionState, setPermissionState] =
    useState<PermissionState | null>(null);

  const [showPermissionDeniedModal, setShowPermissionDeniedModal] =
    useState(false);

  // ========================= INITIALIZE =========================
  useEffect(() => {
    const checkForSubscription = async () => {
      if ("serviceWorker" in navigator && "PushManager" in window) {
        setIsSupportedByBrowser(true);
        const registration = await navigator.serviceWorker.ready;
        const sub = await registration.pushManager.getSubscription();
        setSubscription(sub);

        const permissionState = await registration.pushManager.permissionState({
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(
            process.env.NEXT_PUBLIC_VAPID_PUBLIC_KEY!
          ),
        });
        setPermissionState(permissionState);
      }
    };

    checkForSubscription();
  }, []);

  // ========================= INTERNAL HELPERS =========================
  const handleSubscribe = useCallback(
    async (sub: PushSubscription) => {
      const subscriptionData = sub.toJSON();
      const { keys, endpoint } = subscriptionData;
      if (endpoint && keys) {
        await subscribeUser(tenantUuid, {
          endpoint,
          keys,
        });
      }
    },
    [tenantUuid]
  );

  const handleUnsubscribe = useCallback(
    async (sub: PushSubscription) => {
      const subscriptionData = sub.toJSON();
      const { endpoint } = subscriptionData;

      if (endpoint) {
        await unsubscribeUser(tenantUuid, {
          endpoint,
        });
      }
    },
    [tenantUuid]
  );

  // ========================= EXPOSED METHODS =========================
  const subscribeToPush = useCallback(async () => {
    if (permissionState === "denied") {
      setShowPermissionDeniedModal(true);
      return;
    }

    const registration = await navigator.serviceWorker.ready;
    try {
      const sub = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(
          process.env.NEXT_PUBLIC_VAPID_PUBLIC_KEY!
        ),
      });

      setSubscription(sub);
      setPermissionState("granted");
      await handleSubscribe(sub);
    } catch (error) {
      if (error instanceof DOMException && error.name === "NotAllowedError") {
        setPermissionState("denied");
        analytics.track("Notification", "Permission Denied");
        return;
      }

      throw error;
    }
  }, [analytics, handleSubscribe, permissionState]);

  const unsubscribeFromPush = useCallback(async () => {
    await subscription?.unsubscribe();
    if (!subscription) {
      return;
    }

    await handleUnsubscribe(subscription);
    setSubscription(null);
  }, [subscription, handleUnsubscribe]);

  const closePermissionDeniedModal = useCallback(() => {
    setShowPermissionDeniedModal(false);
  }, []);

  const isSupported = isSupportedByBrowser && isUsingPwa();

  return useMemo(() => {
    return {
      closePermissionDeniedModal,
      isSupported,
      permissionState,
      showPermissionDeniedModal,
      subscription,
      subscribeToPush,
      unsubscribeFromPush,
    };
  }, [
    closePermissionDeniedModal,
    isSupported,
    permissionState,
    showPermissionDeniedModal,
    subscription,
    subscribeToPush,
    unsubscribeFromPush,
  ]);
};
