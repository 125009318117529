import React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import { clsxm } from "@/lib/clsxm";

import { Button } from "@/components/buttons/Button";
import { TextButton } from "@/components/buttons/TextButton";
import { TextInput } from "@/components/inputs/TextInput";

import { upsmithApiKey } from "@/constant/env";
import { useSendMagicLinkEmail } from "@/upsmith-api";

import { getEmailLoginErrorDisplayMessage } from "./getEmailLoginErrorDisplayMessage";
import { EmailLoginFormValues } from "./types";
import { getLoginRedirectUrl } from "../utils";

export const EmailLogin = ({
  isMagicLinkSent,
  onMagicLinkSent,
}: {
  isMagicLinkSent: boolean;
  onMagicLinkSent: () => void;
}) => {
  const formMethods = useForm<EmailLoginFormValues>({
    mode: "onTouched",
  });
  const { handleSubmit, formState, register, watch } = formMethods;

  const isFormValid = !!formState.dirtyFields.email;

  const {
    mutate: sendMagicLinkEmail,
    error: emailError,
    isLoading,
  } = useSendMagicLinkEmail({
    request: {
      headers: {
        "x-api-key": upsmithApiKey,
      },
    },
    mutation: {
      onSuccess: () => {
        onMagicLinkSent();
      },
    },
  });

  const onEmailSubmit: SubmitHandler<EmailLoginFormValues> = React.useCallback(
    (formData) => {
      if (formData?.email) {
        sendMagicLinkEmail({
          data: {
            email: formData.email,
            redirect_url: getLoginRedirectUrl(),
          },
        });
      }
    },
    [sendMagicLinkEmail]
  );

  const emailState = watch("email");

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onEmailSubmit)}>
        {isMagicLinkSent && (
          <section>
            <div>
              {emailState ? (
                <h4>
                  We just sent you an email at{" "}
                  <b className="inline-block">{emailState}</b>. It contains a
                  link that will log you in to UpSmith.
                </h4>
              ) : (
                <h4>
                  We just sent you an email. It contains a link that will log
                  you in to UpSmith.
                </h4>
              )}
            </div>
            <p className="mt-4">
              Message not received? &nbsp;
              <TextButton type="submit">Resend Link</TextButton>
            </p>
          </section>
        )}
        <div className={clsxm(isMagicLinkSent ? "hidden" : "relative")}>
          <div className="flex flex-col gap-y-4">
            <div>
              <h4>Enter your email and we will send a login link.</h4>
            </div>
            <TextInput {...register("email")} />
            {!!emailError && (
              <p className="text-red-500">
                {getEmailLoginErrorDisplayMessage(emailError)}
              </p>
            )}
            <Button
              type="submit"
              size="lg"
              rounded
              fullWidth
              isLoading={isLoading}
              disabled={!isFormValid || isLoading}
            >
              Send Link to Email
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
