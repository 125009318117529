import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { usePatternFormat } from "react-number-format";

import { clsxm } from "@/lib/clsxm";

import { Button } from "@/components/buttons/Button";
import { TextButton } from "@/components/buttons/TextButton";
import { PhoneFormInput } from "@/components/inputs/PhoneInput";

import { upsmithApiKey } from "@/constant/env";
import { useSendMagicLinkSms } from "@/upsmith-api";

import { getPhoneLoginErrorDisplayMessage } from "./getPhoneLoginErrorDisplayMessage";
import { SMSLoginFormValues } from "./types";
import { getLoginRedirectUrl } from "../utils";

export const SmsLogin = ({
  isMagicLinkSent,
  onMagicLinkSent,
}: {
  isMagicLinkSent: boolean;
  onMagicLinkSent: () => void;
}) => {
  const formMethods = useForm<SMSLoginFormValues>({
    mode: "onTouched",
  });
  const { handleSubmit, formState, register, watch } = formMethods;

  const isFormValid = !!formState.dirtyFields.phone;

  const {
    mutate: sendMagicLinkSMS,
    error: smsError,
    isLoading,
  } = useSendMagicLinkSms({
    request: {
      headers: {
        "x-api-key": upsmithApiKey,
      },
    },
    mutation: {
      onSuccess: () => {
        onMagicLinkSent();
      },
    },
  });

  const onSMSSubmit: SubmitHandler<SMSLoginFormValues> = React.useCallback(
    (formData) => {
      if (formData?.phone) {
        sendMagicLinkSMS({
          data: {
            phone: formData.phone,
            redirect_url: getLoginRedirectUrl(),
          },
        });
      }
    },
    [sendMagicLinkSMS]
  );

  const { format } = usePatternFormat({
    format: "+1 (###) ###-####",
    allowEmptyFormatting: true,
  });

  const phoneState = watch("phone");

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSMSSubmit)}>
        {isMagicLinkSent && (
          <section>
            <div>
              {phoneState ? (
                <h4>
                  We just sent you a text message at{" "}
                  <b className="inline-block">{format(phoneState)}</b>. It
                  contains a link that will log you in to UpSmith.
                </h4>
              ) : (
                <h4>
                  We just sent you a text message. It contains a link that will
                  log you in to UpSmith.
                </h4>
              )}
            </div>
            <p className="mt-4">
              Message not received? &nbsp;
              <TextButton type="submit">Resend Link</TextButton>
            </p>
          </section>
        )}
        <div className={clsxm(isMagicLinkSent ? "hidden" : "relative")}>
          <div className="flex flex-col gap-y-4">
            <div>
              <h4>
                Enter your phone number and we will text a login link to your
                phone.
              </h4>
            </div>
            <PhoneFormInput {...register("phone")} />
            {!!smsError && (
              <p className=" text-red-500">
                {getPhoneLoginErrorDisplayMessage(smsError)}
              </p>
            )}
            <Button
              type="submit"
              size="lg"
              rounded
              fullWidth
              isLoading={isLoading}
              disabled={!isFormValid || isLoading}
            >
              Send Link to Phone
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
