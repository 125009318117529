/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type MultiOptionBehaviorCreateSchemaType =
  (typeof MultiOptionBehaviorCreateSchemaType)[keyof typeof MultiOptionBehaviorCreateSchemaType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MultiOptionBehaviorCreateSchemaType = {
  multi_option: "multi_option",
} as const;
