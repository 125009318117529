/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type MembershipSoldTriggerConfigSchemaTriggerType =
  (typeof MembershipSoldTriggerConfigSchemaTriggerType)[keyof typeof MembershipSoldTriggerConfigSchemaTriggerType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipSoldTriggerConfigSchemaTriggerType = {
  membership_sold: "membership_sold",
} as const;
