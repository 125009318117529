/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  CreateRaffleDrawingBody,
  CreateRaffleDrawingsSchema,
  HTTPValidationError,
  RaffleDetailedSchema,
  RaffleDrawingSchema,
  RaffleEntry,
  RaffleSchema,
} from ".././schema";
import { axiosInstance } from "../../lib/axios";
import type { ErrorType } from "../../lib/axios";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Get Raffles
 */
export const getRaffles = (
  tenantUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<RaffleSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/raffles`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetRafflesQueryKey = (tenantUuid: string) => {
  return [`/api/v1/tenants/${tenantUuid}/boost/raffles`] as const;
};

export const getGetRafflesQueryOptions = <
  TData = Awaited<ReturnType<typeof getRaffles>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getRaffles>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetRafflesQueryKey(tenantUuid);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRaffles>>> = ({
    signal,
  }) => getRaffles(tenantUuid, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getRaffles>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetRafflesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getRaffles>>
>;
export type GetRafflesQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Raffles
 */
export const useGetRaffles = <
  TData = Awaited<ReturnType<typeof getRaffles>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getRaffles>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetRafflesQueryOptions(tenantUuid, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get Raffle
 */
export const getRaffle = (
  tenantUuid: string,
  raffleUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<RaffleDetailedSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/raffles/${raffleUuid}`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetRaffleQueryKey = (
  tenantUuid: string,
  raffleUuid: string
) => {
  return [`/api/v1/tenants/${tenantUuid}/boost/raffles/${raffleUuid}`] as const;
};

export const getGetRaffleQueryOptions = <
  TData = Awaited<ReturnType<typeof getRaffle>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  raffleUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getRaffle>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetRaffleQueryKey(tenantUuid, raffleUuid);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRaffle>>> = ({
    signal,
  }) => getRaffle(tenantUuid, raffleUuid, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(tenantUuid && raffleUuid),
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof getRaffle>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GetRaffleQueryResult = NonNullable<
  Awaited<ReturnType<typeof getRaffle>>
>;
export type GetRaffleQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Raffle
 */
export const useGetRaffle = <
  TData = Awaited<ReturnType<typeof getRaffle>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  raffleUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getRaffle>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetRaffleQueryOptions(
    tenantUuid,
    raffleUuid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get Raffle Entries
 */
export const getRaffleEntries = (
  tenantUuid: string,
  raffleUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<RaffleEntry[]>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/raffles/${raffleUuid}/entries`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetRaffleEntriesQueryKey = (
  tenantUuid: string,
  raffleUuid: string
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/boost/raffles/${raffleUuid}/entries`,
  ] as const;
};

export const getGetRaffleEntriesQueryOptions = <
  TData = Awaited<ReturnType<typeof getRaffleEntries>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  raffleUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getRaffleEntries>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetRaffleEntriesQueryKey(tenantUuid, raffleUuid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getRaffleEntries>>
  > = ({ signal }) =>
    getRaffleEntries(tenantUuid, raffleUuid, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(tenantUuid && raffleUuid),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getRaffleEntries>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetRaffleEntriesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getRaffleEntries>>
>;
export type GetRaffleEntriesQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Raffle Entries
 */
export const useGetRaffleEntries = <
  TData = Awaited<ReturnType<typeof getRaffleEntries>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  raffleUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getRaffleEntries>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetRaffleEntriesQueryOptions(
    tenantUuid,
    raffleUuid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Create Raffle Drawing
 */
export const createRaffleDrawing = (
  tenantUuid: string,
  raffleUuid: string,
  createRaffleDrawingBody: CreateRaffleDrawingBody,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<RaffleDrawingSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/raffles/${raffleUuid}/actions/draw`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: createRaffleDrawingBody,
    },
    options
  );
};

export const getCreateRaffleDrawingMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createRaffleDrawing>>,
    TError,
    { tenantUuid: string; raffleUuid: string; data: CreateRaffleDrawingBody },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createRaffleDrawing>>,
  TError,
  { tenantUuid: string; raffleUuid: string; data: CreateRaffleDrawingBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createRaffleDrawing>>,
    { tenantUuid: string; raffleUuid: string; data: CreateRaffleDrawingBody }
  > = (props) => {
    const { tenantUuid, raffleUuid, data } = props ?? {};

    return createRaffleDrawing(tenantUuid, raffleUuid, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateRaffleDrawingMutationResult = NonNullable<
  Awaited<ReturnType<typeof createRaffleDrawing>>
>;
export type CreateRaffleDrawingMutationBody = CreateRaffleDrawingBody;
export type CreateRaffleDrawingMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Create Raffle Drawing
 */
export const useCreateRaffleDrawing = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createRaffleDrawing>>,
    TError,
    { tenantUuid: string; raffleUuid: string; data: CreateRaffleDrawingBody },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createRaffleDrawing>>,
  TError,
  { tenantUuid: string; raffleUuid: string; data: CreateRaffleDrawingBody },
  TContext
> => {
  const mutationOptions = getCreateRaffleDrawingMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Create Many Raffle Drawing
 */
export const createManyRaffleDrawing = (
  tenantUuid: string,
  raffleUuid: string,
  createRaffleDrawingsSchema: CreateRaffleDrawingsSchema,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<RaffleDrawingSchema[]>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/raffles/${raffleUuid}/actions/draw-many`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: createRaffleDrawingsSchema,
    },
    options
  );
};

export const getCreateManyRaffleDrawingMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createManyRaffleDrawing>>,
    TError,
    {
      tenantUuid: string;
      raffleUuid: string;
      data: CreateRaffleDrawingsSchema;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createManyRaffleDrawing>>,
  TError,
  { tenantUuid: string; raffleUuid: string; data: CreateRaffleDrawingsSchema },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createManyRaffleDrawing>>,
    { tenantUuid: string; raffleUuid: string; data: CreateRaffleDrawingsSchema }
  > = (props) => {
    const { tenantUuid, raffleUuid, data } = props ?? {};

    return createManyRaffleDrawing(
      tenantUuid,
      raffleUuid,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateManyRaffleDrawingMutationResult = NonNullable<
  Awaited<ReturnType<typeof createManyRaffleDrawing>>
>;
export type CreateManyRaffleDrawingMutationBody = CreateRaffleDrawingsSchema;
export type CreateManyRaffleDrawingMutationError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Create Many Raffle Drawing
 */
export const useCreateManyRaffleDrawing = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createManyRaffleDrawing>>,
    TError,
    {
      tenantUuid: string;
      raffleUuid: string;
      data: CreateRaffleDrawingsSchema;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createManyRaffleDrawing>>,
  TError,
  { tenantUuid: string; raffleUuid: string; data: CreateRaffleDrawingsSchema },
  TContext
> => {
  const mutationOptions = getCreateManyRaffleDrawingMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Confirm Raffle Winner
 */
export const confirmRaffleWinner = (
  tenantUuid: string,
  raffleDrawingUuid: string,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<RaffleDrawingSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/raffle-drawings/${raffleDrawingUuid}/actions/confirm`,
      method: "POST",
    },
    options
  );
};

export const getConfirmRaffleWinnerMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof confirmRaffleWinner>>,
    TError,
    { tenantUuid: string; raffleDrawingUuid: string },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof confirmRaffleWinner>>,
  TError,
  { tenantUuid: string; raffleDrawingUuid: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof confirmRaffleWinner>>,
    { tenantUuid: string; raffleDrawingUuid: string }
  > = (props) => {
    const { tenantUuid, raffleDrawingUuid } = props ?? {};

    return confirmRaffleWinner(tenantUuid, raffleDrawingUuid, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ConfirmRaffleWinnerMutationResult = NonNullable<
  Awaited<ReturnType<typeof confirmRaffleWinner>>
>;

export type ConfirmRaffleWinnerMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Confirm Raffle Winner
 */
export const useConfirmRaffleWinner = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof confirmRaffleWinner>>,
    TError,
    { tenantUuid: string; raffleDrawingUuid: string },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof confirmRaffleWinner>>,
  TError,
  { tenantUuid: string; raffleDrawingUuid: string },
  TContext
> => {
  const mutationOptions = getConfirmRaffleWinnerMutationOptions(options);

  return useMutation(mutationOptions);
};
