/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */
import { useMutation } from "@tanstack/react-query";
import type {
  MutationFunction,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import type {
  BoostSendMagicLinkEmailInput,
  BoostSendMagicLinkSMSInput,
  HTTPValidationError,
} from ".././schema";
import { axiosInstance } from "../../lib/axios";
import type { ErrorType } from "../../lib/axios";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Send Magic Link Sms
 */
export const sendMagicLinkSms = (
  boostSendMagicLinkSMSInput: BoostSendMagicLinkSMSInput,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<unknown>(
    {
      url: `/api/v1/tenants/auth/actions/send-magic-link`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: boostSendMagicLinkSMSInput,
    },
    options
  );
};

export const getSendMagicLinkSmsMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sendMagicLinkSms>>,
    TError,
    { data: BoostSendMagicLinkSMSInput },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof sendMagicLinkSms>>,
  TError,
  { data: BoostSendMagicLinkSMSInput },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof sendMagicLinkSms>>,
    { data: BoostSendMagicLinkSMSInput }
  > = (props) => {
    const { data } = props ?? {};

    return sendMagicLinkSms(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SendMagicLinkSmsMutationResult = NonNullable<
  Awaited<ReturnType<typeof sendMagicLinkSms>>
>;
export type SendMagicLinkSmsMutationBody = BoostSendMagicLinkSMSInput;
export type SendMagicLinkSmsMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Send Magic Link Sms
 */
export const useSendMagicLinkSms = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sendMagicLinkSms>>,
    TError,
    { data: BoostSendMagicLinkSMSInput },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof sendMagicLinkSms>>,
  TError,
  { data: BoostSendMagicLinkSMSInput },
  TContext
> => {
  const mutationOptions = getSendMagicLinkSmsMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Send Magic Link Email
 */
export const sendMagicLinkEmail = (
  boostSendMagicLinkEmailInput: BoostSendMagicLinkEmailInput,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<unknown>(
    {
      url: `/api/v1/tenants/auth/actions/send-magic-link-email`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: boostSendMagicLinkEmailInput,
    },
    options
  );
};

export const getSendMagicLinkEmailMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sendMagicLinkEmail>>,
    TError,
    { data: BoostSendMagicLinkEmailInput },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof sendMagicLinkEmail>>,
  TError,
  { data: BoostSendMagicLinkEmailInput },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof sendMagicLinkEmail>>,
    { data: BoostSendMagicLinkEmailInput }
  > = (props) => {
    const { data } = props ?? {};

    return sendMagicLinkEmail(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SendMagicLinkEmailMutationResult = NonNullable<
  Awaited<ReturnType<typeof sendMagicLinkEmail>>
>;
export type SendMagicLinkEmailMutationBody = BoostSendMagicLinkEmailInput;
export type SendMagicLinkEmailMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Send Magic Link Email
 */
export const useSendMagicLinkEmail = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sendMagicLinkEmail>>,
    TError,
    { data: BoostSendMagicLinkEmailInput },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof sendMagicLinkEmail>>,
  TError,
  { data: BoostSendMagicLinkEmailInput },
  TContext
> => {
  const mutationOptions = getSendMagicLinkEmailMutationOptions(options);

  return useMutation(mutationOptions);
};
