import {
  AuthProvider,
  RedirectToLogin,
  RequiredAuthProviderProps,
  WithLoggedInAuthInfoProps,
  withRequiredAuthInfo,
} from "@propelauth/react";
import * as React from "react";

import { LoggedOutHeader } from "@/components/layout/Header/LoggedOutHeader";
import { CurrentUserProvider } from "@/components/providers/CurrentUserProvider";
import { Spinner } from "@/components/Spinner";

const RequiredAuthWrappedComponent = withRequiredAuthInfo(
  ({
    children,
    displayWhileLoading,
  }: {
    children: React.ReactNode;
    displayWhileLoading: React.ReactNode;
  } & WithLoggedInAuthInfoProps) => {
    return (
      <CurrentUserProvider displayWhileLoading={displayWhileLoading}>
        {children}
      </CurrentUserProvider>
    );
  }
);

export const RequiredAuthProvider = (props: RequiredAuthProviderProps) => {
  const { children, displayIfLoggedOut, displayWhileLoading, ...sharedProps } =
    props;

  const origin =
    typeof window !== "undefined" ? window?.location?.origin : undefined;

  const whileLoading = React.useMemo(() => {
    return (
      displayWhileLoading || (
        <div className="bg-gray-50 antialiased">
          <LoggedOutHeader />
          <main className="layout m-4 mx-auto min-h-screen sm:pt-[70px]">
            <Spinner />
          </main>
        </div>
      )
    );
  }, [displayWhileLoading]);

  return (
    <AuthProvider
      {...sharedProps}
      defaultDisplayIfLoggedOut={
        displayIfLoggedOut || <RedirectToLogin postLoginRedirectUrl={origin} />
      }
      defaultDisplayWhileLoading={whileLoading}
    >
      <RequiredAuthWrappedComponent displayWhileLoading={whileLoading}>
        {children}
      </RequiredAuthWrappedComponent>
    </AuthProvider>
  );
};
