/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type ValidBulkUserUploadResponseWithKeyResult =
  (typeof ValidBulkUserUploadResponseWithKeyResult)[keyof typeof ValidBulkUserUploadResponseWithKeyResult];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ValidBulkUserUploadResponseWithKeyResult = {
  valid: "valid",
} as const;
