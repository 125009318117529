import { pageRoutes } from "@/constant/pageRoutes";

export const getLoginRedirectUrl = () => {
  const currentLocalUrl = window.location.href;

  if (currentLocalUrl.includes(pageRoutes.login)) {
    return null;
  }

  return currentLocalUrl;
};
