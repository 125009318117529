import { OrgMemberInfo } from "@propelauth/javascript";
import { UseAuthInfoLoggedInProps } from "@propelauth/react/dist/types/hooks/useAuthInfo";

export type AuthData = {
  email: string;
  impersonatorUserId?: string;
  orgId?: string;
  tenantUuid: string;
};

const getOrgUuid = (authInfo: UseAuthInfoLoggedInProps): string | undefined => {
  if (authInfo.user.properties?.metadata) {
    const metadata = authInfo.user.properties?.metadata as Record<
      string,
      string
    >;
    return metadata.organization_uuid;
  }
};

const getTenantUuid = (authInfo: UseAuthInfoLoggedInProps): string => {
  const userOrg: OrgMemberInfo = authInfo.orgHelper?.getOrgs()[0];

  return userOrg.orgMetadata.tenant_uuid;
};

export const extractDataFromAuthInfo = (
  authInfo: UseAuthInfoLoggedInProps
): AuthData => {
  const orgId = getOrgUuid(authInfo);
  const tenantUuid = getTenantUuid(authInfo);
  const authedSessionInfo = {
    orgId: orgId,
    tenantUuid: tenantUuid,
    email: authInfo.user.email,
    impersonatorUserId: authInfo.impersonatorUserId,
  };
  return authedSessionInfo;
};
