/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type SkuSoldQualifiedBehaviorOutboxSchemaType =
  (typeof SkuSoldQualifiedBehaviorOutboxSchemaType)[keyof typeof SkuSoldQualifiedBehaviorOutboxSchemaType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SkuSoldQualifiedBehaviorOutboxSchemaType = {
  sku_sold: "sku_sold",
} as const;
