/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type BoostUserCompetitionEntrySchemaType =
  (typeof BoostUserCompetitionEntrySchemaType)[keyof typeof BoostUserCompetitionEntrySchemaType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BoostUserCompetitionEntrySchemaType = {
  user: "user",
} as const;
