/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type SkuSoldHandlerConfigSchemaHandlerType =
  (typeof SkuSoldHandlerConfigSchemaHandlerType)[keyof typeof SkuSoldHandlerConfigSchemaHandlerType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SkuSoldHandlerConfigSchemaHandlerType = {
  sku_sold_competition: "sku_sold_competition",
} as const;
