import { AxiosError } from "axios";

export const getEmailLoginErrorDisplayMessage = (error: AxiosError) => {
  const statusCode = error?.response?.status;
  if (statusCode === 422) {
    return "Email is invalid";
  }
  if (statusCode === 401) {
    return "No user found with that email.";
  }

  return "There was an error logging in. Please try again later.";
};
