import React from "react";

import { clsxm } from "@/lib/clsxm";
import { ThemedLogo } from "@/lib/orgConfig";

import { UnstyledLink } from "@/components/links/UnstyledLink";

type Props = {
  children?: React.ReactNode;
  showOnMobile?: boolean;
};

export const BaseHeader: React.FC<Props> = ({ children, showOnMobile }) => {
  return (
    <div
      className={clsxm(
        "fixed inset-x-0 top-0 z-20 h-[70px] w-full flex-row items-center justify-between border-b border-bordercolor bg-white px-5",
        !showOnMobile && "hidden sm:flex",
        showOnMobile && "flex justify-center sm:justify-between"
      )}
    >
      <UnstyledLink href="/">
        <ThemedLogo height={38} />
      </UnstyledLink>
      {children}
    </div>
  );
};
