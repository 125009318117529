import { RedirectToLogin } from "@propelauth/react";
import * as React from "react";

import { Button } from "@/components/buttons/Button";

export const PasswordLogin = () => {
  const [showPasswordLogin, setShowPasswordLogin] = React.useState(false);
  if (showPasswordLogin) {
    return <RedirectToLogin postLoginRedirectUrl={origin} />;
  }
  return (
    <div>
      <h4>
        If this is your first time logging in, you can set up your password by
        selecting "Forgot password" on the next page.
      </h4>
      <Button
        className="mt-4"
        fullWidth
        size="lg"
        rounded
        variant="primary"
        onClick={() => {
          setShowPasswordLogin(true);
        }}
      >
        Continue to Login
      </Button>
    </div>
  );
};
