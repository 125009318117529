import * as React from "react";
import { IconType } from "react-icons";
import { ImSpinner2 } from "react-icons/im";

import { clsxm } from "@/lib/clsxm";

export const ButtonVariant = [
  "primary",
  "outline",
  "light-primary",
  "gray",
  "dark",
  "danger",
  "light-danger",
] as const;
const ButtonSize = ["sm", "base", "lg"] as const;

type ButtonProps = {
  isLoading?: boolean;
  variant?: (typeof ButtonVariant)[number];
  size?: (typeof ButtonSize)[number];
  leftIcon?: IconType;
  rightIcon?: IconType;
  leftIconClassName?: string;
  rounded?: boolean;
  rightIconClassName?: string;
  fullWidth?: boolean;
} & React.ComponentPropsWithRef<"button">;

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      disabled: buttonDisabled,
      isLoading,
      variant = "primary",
      size = "base",
      leftIcon: LeftIcon,
      rightIcon: RightIcon,
      leftIconClassName,
      rightIconClassName,
      rounded,
      fullWidth,
      ...rest
    },
    ref
  ) => {
    const disabled = isLoading || buttonDisabled;

    return (
      <button
        ref={ref}
        type="button"
        disabled={disabled}
        className={clsxm(
          "inline-flex items-center rounded-lg font-normal",
          "focus:outline-none focus-visible:ring focus-visible:ring-primary-500",
          "text-nowrap text-center leading-none",
          "transition-colors",
          //#region  //*=========== Size ===========
          [
            size === "base" && ["px-6 py-2.5", "text-sm"],
            size === "sm" && ["px-4 py-2", "text-xs"],
            size === "lg" && ["px-8 py-2.5", "text-base"],
          ],
          //#endregion  //*======== Size ===========
          //#region  //*=========== Variants ===========
          [
            variant === "primary" && [
              "text-white",
              "bg-primary-500",
              "border border-primary-500",
              "hover:border-primary-600 hover:bg-primary-600",
              "active:bg-primary-700",
            ],
            variant === "outline" && [
              "text-gray-700",
              "bg-white",
              "border border-gray-200",
              "hover:border-gray-500 hover:bg-gray-50 hover:text-gray-800",
              "active:border-gray-800 active:bg-white active:text-gray-900",
            ],
            variant === "gray" && [
              "text-gray-600",
              "bg-gray-10",
              "border border-gray-10",
              "hover:border-gray-50 hover:bg-gray-50 hover:text-gray-900",
              "active:border-gray-100 active:bg-gray-100 active:text-gray-900",
            ],
            variant === "light-primary" && [
              "text-primary-500",
              "bg-primary-50",
              "border border-primary-50",
              "hover:border-primary-100 hover:bg-primary-100 hover:text-primary-600",
              "active:border-primary-200 active:bg-primary-200 active:text-primary-700",
            ],
            variant === "dark" && [
              "bg-gray-900 text-white",
              "border border-gray-900",
              "hover:border-gray-800 hover:bg-gray-800",
              "active:border-gray-700 active:bg-gray-700",
            ],
            variant === "danger" && [
              "text-white",
              "bg-danger-500",
              "border border-danger-500",
              "hover:border-danger-600 hover:bg-danger-600",
              "active:border-danger-700 active:bg-danger-700",
            ],
            variant === "light-danger" && [
              "text-danger-500",
              "bg-danger-50",
              "border border-danger-50",
              "hover:bg-danger-100 hover:text-danger-600",
              "active:border-danger-200 active:bg-danger-200 active:text-danger-700",
            ],
          ],
          //#endregion  //*======== Variants ===========
          "disabled:cursor-not-allowed disabled:opacity-50",
          isLoading &&
            "relative text-transparent transition-none hover:text-transparent disabled:cursor-wait",
          rounded && [
            "rounded-full",
            size === "base" && ["px-6"],
            size === "sm" && ["px-4"],
            size === "lg" && ["px-8"],
          ],
          fullWidth && "w-full justify-center",
          className
        )}
        {...rest}
      >
        {isLoading && (
          <div
            className={clsxm(
              "absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2",
              {
                "text-white": ["primary", "dark", "gray"].includes(variant),
                "text-black": ["light"].includes(variant),
                "text-primary-500": ["outline"].includes(variant),
              }
            )}
          >
            <ImSpinner2 className="animate-spin" />
          </div>
        )}
        {LeftIcon && (
          <div
            className={clsxm([
              children && size === "lg" && "mr-2",
              children && size === "base" && "mr-1.5",
              children && size === "sm" && "mr-1.5",
            ])}
          >
            <LeftIcon
              className={clsxm(
                [
                  size === "lg" && "text-lg",
                  size === "base" && "text-lg",
                  size === "sm" && "text-sm",
                ],
                leftIconClassName
              )}
            />
          </div>
        )}
        {children}
        {RightIcon && (
          <div
            className={clsxm([
              children && size === "lg" && "ml-2",
              children && size === "base" && "ml-1.5",
              children && size === "sm" && "ml-1.5",
            ])}
          >
            <RightIcon
              className={clsxm(
                [
                  size === "lg" && "text-lg",
                  size === "base" && "text-lg",
                  size === "sm" && "text-sm",
                ],
                rightIconClassName
              )}
            />
          </div>
        )}
      </button>
    );
  }
);
