/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type PricebookItemType =
  (typeof PricebookItemType)[keyof typeof PricebookItemType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PricebookItemType = {
  equipment: "equipment",
  material: "material",
  service: "service",
  price_modifier: "price_modifier",
} as const;
