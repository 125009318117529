import { useCallback, useMemo, useState } from "react";

import { parseMessageEvent } from "./parseMessageEvent";
import { ServiceWorkerEvent, ServiceWorkerEventManagerService } from "./types";

export const useServiceWorkerEventManagerService =
  (): ServiceWorkerEventManagerService => {
    const [allEvents, setAllEvents] = useState<Array<ServiceWorkerEvent>>([]);
    const [processedEvents, setProcessedEvents] = useState<
      Array<ServiceWorkerEvent>
    >([]);

    const nextEvent = useMemo(() => {
      const unprocessedEvents = allEvents.filter((event) => {
        return !processedEvents.includes(event);
      });
      return unprocessedEvents[0] || null;
    }, [allEvents, processedEvents]);

    const processEvent = useCallback((event: ServiceWorkerEvent) => {
      setProcessedEvents((prevEvents) => {
        return [...prevEvents, event];
      });
    }, []);

    const addEvent = useCallback(
      (event: MessageEvent) => {
        const parsedEvent = parseMessageEvent(event);

        if (!parsedEvent) {
          return;
        }

        setAllEvents((prevEvents) => {
          return [...prevEvents, parsedEvent];
        });
      },
      [setAllEvents]
    );

    return {
      addEvent,
      allEvents,
      nextEvent,
      processEvent: processEvent,
    };
  };
