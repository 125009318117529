/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type BoostUserSchemaRole =
  (typeof BoostUserSchemaRole)[keyof typeof BoostUserSchemaRole];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BoostUserSchemaRole = {
  admin: "admin",
  technician: "technician",
  admin_technician: "admin_technician",
} as const;
