import { ServiceWorkerEvent } from "./types";

export const parseMessageEvent = (
  event: MessageEvent
): ServiceWorkerEvent | null => {
  if (event.data.event === "pushReceived") {
    return {
      type: "pushReceived",
      data: {
        title: event.data.data.title,
        body: event.data.data.body,
        uuid: event.data.data.uuid,
      },
    };
  }

  if (event.data.event === "notificationClicked") {
    return {
      type: "notificationClicked",
      data: {
        title: event.data.data.title,
        body: event.data.data.body,
        uuid: event.data.data.uuid,
      },
    };
  }

  if (event.data.event === "notificationDismissed") {
    return {
      type: "notificationDismissed",
      data: {
        title: event.data.data.title,
        body: event.data.data.body,
        uuid: event.data.data.uuid,
      },
    };
  }

  return null;
};
