import React from "react";
import { isIOS, isMobile } from "react-device-detect";

import { isUsingPwa } from "@/lib/pwa";

import { isLocal } from "@/constant/env";

import { LoginOption } from "./types";

export const useLoginOptions = () => {
  const isPwa = isUsingPwa();
  const canLoginWithPassword = isLocal || isPwa;
  const mustLogInWithPassword = isPwa && isIOS;

  const loginOptions = React.useMemo(() => {
    const phoneOption: LoginOption = {
      label: "Login via phone number",
      value: "phone",
    };
    const emailOption: LoginOption = {
      label: "Login via email",
      value: "email",
    };
    const passwordOption: LoginOption = {
      label: "Login with password",
      value: "password",
    };

    if (mustLogInWithPassword) {
      return [passwordOption];
    }

    if (canLoginWithPassword) {
      return [emailOption, phoneOption, passwordOption];
    }

    return [emailOption, phoneOption];
  }, [canLoginWithPassword, mustLogInWithPassword]);

  const defaultLoginOption = React.useMemo(() => {
    const phoneOption = loginOptions.find((option) => {
      return option.value === "phone";
    });
    if (isMobile && phoneOption) {
      return phoneOption;
    }
    return loginOptions[0];
  }, [loginOptions]);

  return { loginOptions, defaultLoginOption };
};
