import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
} from "@tanstack/react-query";
import { useRef } from "react";

import { PaginationInfo } from "@/upsmith-api";

type InfiniteSearchPage<O> = {
  response: O;
  pageParam: number;
};

export type InfiniteSearchResult<O> = UseInfiniteQueryResult<
  InfiniteSearchPage<O>
>;

type InfiniteResponse<K extends string, O> = {
  [key in K]: Array<O>;
} & {
  metadata?: PaginationInfo | null;
};

type InfiniteQueryOptions = {
  enabled?: boolean;
  refetchInterval?: number;
};

type Params<P, K extends string, O, R extends InfiniteResponse<K, O>> = {
  searchFn: (params: P) => Promise<R>;
  searchParams: P;
  responseKeyName: K;
  cacheKeys: Array<string>;
  limit?: number;
  enabled?: boolean;
  options?: InfiniteQueryOptions;
};

export const useInfiniteSearch = <
  P,
  K extends string,
  O,
  R extends InfiniteResponse<K, O>,
  A extends R[K],
>({
  searchFn,
  searchParams,
  responseKeyName,
  cacheKeys,
  limit = 15,
  enabled = true,
  options = {},
}: Params<P, K, O, R>): InfiniteSearchResult<R> & {
  [key in K]: A;
} => {
  const prevResult = useRef<InfiniteSearchResult<R> | null>(null);

  const result = useInfiniteQuery(
    [cacheKeys, searchParams, responseKeyName],
    async ({ pageParam = 0 }) => {
      const response = await searchFn({
        ...searchParams,
        limit: limit,
        offset: Math.max(pageParam * limit, 0),
      });

      return { response, pageParam };
    },
    {
      enabled,
      getNextPageParam: (lastPage, pages) => {
        return getNextPageParam(lastPage, pages, responseKeyName);
      },
      initialData: prevResult.current?.data
        ? {
            pages: prevResult.current.data.pages,
            pageParams: prevResult.current.data.pageParams,
          }
        : undefined,
      ...options,
    }
  );

  if (result.isFetched) {
    prevResult.current = result;
  }

  return buildSearchResult(result, responseKeyName);
};

const getNextPageParam = <
  K extends string,
  O,
  R extends InfiniteResponse<K, O>,
>(
  lastPage: { pageParam: number; response: R },
  pages: Array<{ response: R }>,
  responseKeyName: K
) => {
  const resultCount = pages.reduce((acc: number, page) => {
    return acc + (page.response[responseKeyName]?.length || 0);
  }, 0);

  if (resultCount === lastPage.response.metadata?.count) {
    return undefined;
  }

  return lastPage.pageParam + 1;
};

const buildSearchResult = <
  K extends string,
  O,
  R extends InfiniteResponse<K, O>,
>(
  result: InfiniteSearchResult<R>,
  responseKeyName: K
): InfiniteSearchResult<R> & { [key in K]: Array<O> } => {
  const data = result.data?.pages.reduce<Array<O>>((prevData, page) => {
    return [...prevData, ...(page?.response[responseKeyName] || [])];
  }, []);

  return { ...result, [responseKeyName]: data || [] };
};
