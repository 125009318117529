/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type BoostModuleRafflesName =
  (typeof BoostModuleRafflesName)[keyof typeof BoostModuleRafflesName];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BoostModuleRafflesName = {
  raffles: "raffles",
} as const;
