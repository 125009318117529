/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type BoostModuleRewardsName =
  (typeof BoostModuleRewardsName)[keyof typeof BoostModuleRewardsName];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BoostModuleRewardsName = {
  rewards: "rewards",
} as const;
