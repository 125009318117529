import * as React from "react";

import { clsxm } from "@/lib/clsxm";

import { InputErrorMessage } from "@/components/inputs/InputErrorMessage";

export type TextInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  inputSize?: "sm" | "base";
  error?: string;
  label?: string;
  inputClassName?: string;
};

export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      label,
      name,
      error,
      className,
      inputClassName,
      type = "text",
      inputSize = "base",
      ...props
    },
    ref
  ) => {
    return (
      <div className={clsxm("flex w-full flex-col text-left", className)}>
        {label && (
          <label className="text-sm text-gray-600" htmlFor={name}>
            {label}
          </label>
        )}
        <input
          type={type}
          name={name}
          className={clsxm(
            "rounded-lg border border-gray-200 leading-none text-gray-900",
            inputSize === "sm" ? "px-3 py-2 text-xs" : "px-3 py-2.5 text-sm",
            inputClassName
          )}
          {...props}
          ref={ref}
        />
        <InputErrorMessage error={error} />
      </div>
    );
  }
);
