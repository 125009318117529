/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type MultiOptionQualifiedBehaviorOutboxSchemaType =
  (typeof MultiOptionQualifiedBehaviorOutboxSchemaType)[keyof typeof MultiOptionQualifiedBehaviorOutboxSchemaType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MultiOptionQualifiedBehaviorOutboxSchemaType = {
  multi_option: "multi_option",
} as const;
