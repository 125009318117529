import { pageRoutes } from "@/constant/pageRoutes";

export const getUrlForNotificationRedirect = (
  pageName: string,
  metadata: Record<string, string> = {}
) => {
  if (!pageName) {
    return pageRoutes.notifications;
  }

  if (pageName === "home") {
    return pageRoutes.home;
  }

  if (pageName === "competition_overview") {
    if (metadata.competition_uuid) {
      return pageRoutes.competitionOverview(metadata.competition_uuid);
    }
  }

  if (pageName === "notifications") {
    return pageRoutes.notifications;
  }

  return pageRoutes.notifications;
};
