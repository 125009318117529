export const pageRoutes = {
  adminDashboard: "/admin/home",
  announcements: "/admin/announcements",
  adminUsers: "/admin/users",
  adminUserDashboard: (userUuid: string) => {
    return `/admin/users/${userUuid}/dashboard`;
  },
  competitionsAdmin: "/competitions/admin",
  competitionDetailsAdmin: (competitionUuid: string) => {
    return `/competitions/admin/${competitionUuid}/details`;
  },
  competitionEntryAdmin: (competitionUuid: string, entryUuid: string) => {
    return `/competitions/admin/${competitionUuid}/entry/${entryUuid}`;
  },
  competitionManageAdmin: (competitionUuid: string) => {
    return `/competitions/admin/${competitionUuid}/manage`;
  },
  competitionOverviewAdmin: (competitionUuid: string) => {
    return `/competitions/admin/${competitionUuid}`;
  },
  competitionEntry: (competitionUuid: string, entryUuid: string) => {
    return `/competitions/${competitionUuid}/entry/${entryUuid}`;
  },
  competitionOverview: (competitionUuid: string) => {
    return `/competitions/${competitionUuid}`;
  },
  competitionRaffle: (competitionUuid: string, raffleUuid: string) => {
    return `/competitions/${competitionUuid}/raffle/${raffleUuid}`;
  },
  competitionRaffleReplay: (competitionUuid: string, raffleUuid: string) => {
    return `/competitions/${competitionUuid}/raffle/${raffleUuid}/replay`;
  },
  competitions: "/competitions",
  feed: "/feed",
  home: "/home",
  login: "/login",
  notifications: "/notifications",
  organizationsList: "/admin/organizations",
  profile: "/profile",
  raffles: "/raffles",
  userProfile: (userUuid: string, profileUuid: string) => {
    return `/users/${userUuid}/profile/${profileUuid}`;
  },
  validate: "/validate",
};
