/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type CommunicationMessageChannel =
  (typeof CommunicationMessageChannel)[keyof typeof CommunicationMessageChannel];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CommunicationMessageChannel = {
  email: "email",
  sms: "sms",
} as const;
