import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import { FullStory, init as initFullStory } from "@fullstory/browser";
import { useAuthInfo } from "@propelauth/react";
import { UseAuthInfoProps } from "@propelauth/react/dist/types/hooks/useAuthInfo";
import {
  Analytics as SegmentAnalytics,
  AnalyticsBrowser,
} from "@segment/analytics-next";
import { setUser as setSentryUser } from "@sentry/react";
import { Router } from "next/router";
import { createContext, useCallback, useEffect, useMemo } from "react";
import { isAndroid, isIOS } from "react-device-detect";

import {
  analyticsProxyUrl,
  datadogClientToken,
  datadogRUMApplicationId,
  env,
  fullStoryOrgId,
  isLocal,
  segmentWriteKey,
} from "@/constant/env";

import { extractDataFromAuthInfo } from "../auth";
import { isUsingPwa } from "../pwa";

const kebabCase = (str: string) => {
  return str.replace(/\s+/g, "-").toLowerCase();
};

const sharedDatadogConfig = {
  clientToken: datadogClientToken,
  sessionSampleRate: 100,
  service: "app-fe",
  version: process.env.VERCEL_GIT_COMMIT_SHA,
  env,
  trackSessionAcrossSubdomains: true,
};

datadogLogs.init({
  ...sharedDatadogConfig,
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
});

datadogRum.init({
  ...sharedDatadogConfig,
  applicationId: datadogRUMApplicationId,
  defaultPrivacyLevel: "mask-user-input",
  allowedTracingUrls: ["https://app.upsmith.com", "https://app.upsmith.tech"],
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  beforeSend: (event) => {
    if (isLocal) {
      // eslint-disable-next-line no-console
      console.debug("Datadog RUM event intercepted", event);
      return false;
    }
    return true;
  },
});

const getUpsmithContext = () => {
  return {
    upsmith: {
      isPwa: isUsingPwa(),
      isIOS,
      isAndroid,
    },
  };
};

type AuthedSessionInfo = {
  email: string;
  impersonatorUserId?: string;
  orgId?: string;
  tenantUuid: string;
};

class Analytics {
  _segmentAnalytics: SegmentAnalytics | AnalyticsBrowser;
  page: SegmentAnalytics["page"];
  category: string;
  authedUserId: string | null = null;

  constructor(
    segmentAnalytics: SegmentAnalytics | AnalyticsBrowser,
    loadDatadogRum = true
  ) {
    this.category = "app-fe";
    this._segmentAnalytics = segmentAnalytics;
    this.page = this._segmentAnalytics.page;

    if (loadDatadogRum) {
      datadogRum.startSessionReplayRecording();
    }

    if (fullStoryOrgId) {
      initFullStory({ orgId: fullStoryOrgId, devMode: isLocal });
    }
  }

  identify(userId: string, user: AuthedSessionInfo) {
    if (this.authedUserId === userId) {
      return;
    }

    this._segmentAnalytics.identify(userId, user);
    datadogRum.setUser({
      id: userId,
      ...user,
    });
    if (fullStoryOrgId) {
      FullStory("setIdentity", {
        uid: userId,
        properties: user,
      });
    }
    setSentryUser({ id: userId, ...user });

    this.authedUserId = userId;
  }

  unidentify() {
    this._segmentAnalytics.reset();
    datadogRum.clearUser();
    if (fullStoryOrgId) {
      FullStory("setIdentity", {});
    }
    setSentryUser(null);

    this.authedUserId = null;
  }

  // TODO: allow category to be set by useAnalytics call
  track(label: string, action: string, properties: object = {}) {
    const category = this.category;

    this._segmentAnalytics.track(
      `${kebabCase(action)}-${kebabCase(label)}`,
      {
        category,
        action,
        label,
        ...properties,
      },
      {
        context: {
          ...getUpsmithContext(),
        },
      }
    );
    datadogRum.addAction(`${category}-${action}-${label}`, { ...properties });
  }

  identifyFromSession(authInfo: UseAuthInfoProps) {
    if (authInfo.loading) {
      return;
    }

    if (authInfo.user) {
      const authedSessionInfo = extractDataFromAuthInfo(authInfo);
      this.identify(authInfo.user.userId, {
        email: authedSessionInfo.email,
        impersonatorUserId: authedSessionInfo.impersonatorUserId,
        orgId: authedSessionInfo.orgId,
        tenantUuid: authedSessionInfo.tenantUuid,
      });
      return;
    }

    if (!authInfo.isLoggedIn) {
      this.unidentify();
      return;
    }

    const exhaustiveCheck: never = authInfo;
    return exhaustiveCheck;
  }
}

export const AnalyticsContext = createContext<Analytics>({} as Analytics);

type Props = {
  children: React.ReactNode;
};
export const AnalyticsProvider = ({ children }: Props) => {
  const analytics = useMemo(() => {
    const segmentAnalytics = AnalyticsBrowser.load(
      {
        writeKey: segmentWriteKey,
      },
      {
        integrations: {
          "Segment.io": {
            apiHost: analyticsProxyUrl,
            protocol: "https",
          },
        },
      }
    );
    return new Analytics(segmentAnalytics);
  }, []);

  const authInfo = useAuthInfo();
  useEffect(() => {
    analytics.identifyFromSession(authInfo);
  }, [analytics, authInfo]);

  const trackPageView = useCallback(() => {
    analytics.page(undefined, undefined, {}, getUpsmithContext());
  }, [analytics]);

  // track initial page load
  // note: in development this will fire twice due to react strict mode
  // https://reactjs.org/docs/strict-mode.html#detecting-unexpected-side-effects
  useEffect(() => {
    trackPageView();
  }, [trackPageView]);

  useEffect(() => {
    const trackAfterRouteChange = () => {
      // Use set timeout to ensure the page view fully loaded before tracking
      setTimeout(() => {
        trackPageView();
      }, 50);
    };

    // Next 13 removed events from the useRouter response but they're still available on the import
    // Migrate to interceptors once they're implemented
    Router.events.on("routeChangeComplete", trackAfterRouteChange);
    return () => {
      return Router.events.off("routeChangeComplete", trackAfterRouteChange);
    };
  }, [analytics, trackPageView]);

  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  );
};
