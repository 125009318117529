import { OrgMemberInfo, useAuthInfo } from "@propelauth/react";

export function useTenantUuid(): string {
  const authInfo = useAuthInfo();
  const orgs = authInfo.orgHelper?.getOrgs() ?? [];
  return getTenantFromOrgs(orgs);
}

export const getTenantFromOrgs = (orgs: Array<OrgMemberInfo>): string => {
  if (orgs.length === 0) {
    throw new Error("User is not a member of an organization");
  }
  const org = orgs[0];

  if (!org.orgMetadata.tenant_uuid) {
    throw new Error("Tenant UUID is missing from organization metadata");
  }

  return org.orgMetadata.tenant_uuid;
};
