/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type CompetitionLeaderboardView =
  (typeof CompetitionLeaderboardView)[keyof typeof CompetitionLeaderboardView];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompetitionLeaderboardView = {
  table: "table",
  canvas: "canvas",
} as const;
