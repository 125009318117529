import { useAuthInfo } from "@propelauth/react";
import { useRouter } from "next/router";
import React from "react";

import { pageRoutes } from "@/constant/pageRoutes";

export const useRedirectToHomeIfLoggedIn = () => {
  const router = useRouter();
  const authInfo = useAuthInfo();
  React.useEffect(() => {
    if (authInfo?.isLoggedIn) {
      router.push(pageRoutes.home);
    }
  }, [authInfo, router]);
};
