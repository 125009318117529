/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type TimeZone = (typeof TimeZone)[keyof typeof TimeZone];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TimeZone = {
  "US/Eastern": "US/Eastern",
  "US/Central": "US/Central",
  "US/Mountain": "US/Mountain",
  "US/Pacific": "US/Pacific",
  "US/Alaska": "US/Alaska",
  "US/Hawaii": "US/Hawaii",
} as const;
