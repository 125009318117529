import { createContext, useEffect, useState } from "react";

import {
  ServiceWorkerEventManagerService,
  useServiceWorkerEventManagerService,
} from "./useServiceWorkerEventManagerService";

type BeforeInstallPromptEvent = Event & {
  prompt?: () => void;
};

export const GlobalEventContext = createContext<{
  promptInstallEvent: BeforeInstallPromptEvent | null;
  serviceWorkerEventManagerService: ServiceWorkerEventManagerService | null;
}>({
  promptInstallEvent: null,
  serviceWorkerEventManagerService: null,
});

export const GlobalEventProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [promptEvent, setPromptEvent] = useState<Event | null>(null);

  useEffect(() => {
    const handler = (event: BeforeInstallPromptEvent) => {
      event.preventDefault();
      setPromptEvent(event);
    };

    window.addEventListener("beforeinstallprompt", handler);

    return () => {
      window.removeEventListener("beforeinstallprompt", handler);
    };
  }, []);

  const serviceWorkerEventManagerService =
    useServiceWorkerEventManagerService();

  const { addEvent } = serviceWorkerEventManagerService;

  useEffect(() => {
    if ("serviceWorker" in navigator && "PushManager" in window) {
      const onMessage = (event: MessageEvent) => {
        addEvent(event);
      };

      navigator.serviceWorker.addEventListener("message", onMessage);

      return () => {
        navigator.serviceWorker.removeEventListener("message", onMessage);
      };
    }
  }, [addEvent]);

  return (
    <GlobalEventContext.Provider
      value={{
        promptInstallEvent: promptEvent,
        serviceWorkerEventManagerService: serviceWorkerEventManagerService,
      }}
    >
      {children}
    </GlobalEventContext.Provider>
  );
};
